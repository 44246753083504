const eyeofophidiaImageArray = [
    'homepage.PNG',
    'analytics.PNG',
    'resultspage.PNG',
    'Matchpage.PNG', 
    'eventpage.PNG',
    '1eventpage.PNG',
    'parameters.PNG',
    'issuespage.PNG',
    'postmatchpage.PNG',
    'register.PNG', 
    'homepagemobile.PNG',
    'matchpagemobile.PNG',
    'parametersmobile.PNG',
    'searchresultsmobile.PNG',
  ]
  const eyeofophidiaImageDescriptionArray = [
    'Home Page',
    'Google Analytics for eyeofophidia.net',
    'Match Search Results',
    'Match Page',
    'Events page',
    'Event page',
    'Search Parameters Dropdown',
    'Issues Page (Admin only)',
    'Match Submission Page (Admin only)',
    'Login/Registration page',
    'Home Page (Mobile)',
    'Match Page (Mobile)',
    'Parameters Dropdown (Mobile)',
    'Match Search Resuts (Mobile)',
  ]

  const savagefeatsImageArray = [
    'savagefeats/homepage.PNG',
    'savagefeats/homepagesection1.PNG',
    'savagefeats/homepageSection2.PNG',
    'savagefeats/homepagesection3.PNG',
    'savagefeats/productionservicespage.PNG',
    'savagefeats/productionservicespage1.PNG',
    'savagefeats/productionportfolioexample.PNG',
    'savagefeats/mobile homepage.PNG',
    'savagefeats/homepagemobile2.PNG',
    'savagefeats/events.PNG',
    'savagefeats/eventpage.PNG', 
    'savagefeats/matchsearchpage.PNG',
    'savagefeats/matchpage.PNG',
    'savagefeats/mobileeventsearch.PNG',
  ]
  const savagefeatsImageDescriptionArray = [
    'Home Page',
    'Home Page',
    'Home Page',
    'Home Page',
    'Production Services Page',
    'Production Services Page',
    'Production Portfolio',
    'Mobile Home Page',
    'Mobile Home Page',
    'Event Search Results',
    'Event Page',
    'Match Search Results',
    'Match Page',
    'Mobile Event Search',
  ]

  export {
    eyeofophidiaImageArray,
    eyeofophidiaImageDescriptionArray,
    savagefeatsImageArray,
    savagefeatsImageDescriptionArray,
  }